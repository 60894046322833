import React from 'react'
import ReactDOM from 'react-dom'
import {
    Snackbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { Link } from '@reach/router'

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: '#000'
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const notificationContainer = document.getElementById('notifications')

export const Notification = ({ notificationOpen = false, autoHideDuration = 2000, handleNotificationClose = () => { }, severity = 'success', notificationMessage = '', link = '', vertical='top', horizontal='center'}) => {
    const classes = useStyles()
    return ReactDOM.createPortal(
        <Snackbar anchorOrigin={{ vertical, horizontal }} styles={{ zIndex: 2000 }} open={notificationOpen} autoHideDuration={autoHideDuration} onClose={handleNotificationClose}>
            {
                link ?
                    <Link to={link} className={classes.link}>
                        <Alert onClose={handleNotificationClose} severity={severity}>
                            {notificationMessage}
                        </Alert>
                    </Link>
                    :
                    <Alert onClose={handleNotificationClose} severity={severity}>
                        {notificationMessage}
                    </Alert>
            }
        </Snackbar>,
        notificationContainer
    )

}




export default Notification