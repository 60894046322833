

export const keyBy = (arr, key) => arr.reduce((acc, el) => {
    acc[el[key]] = el
    return acc
}, {})

export const groupBy = (arr, key) => {
    return arr.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}

export const snapshotToArray = (snapshot) =>
    snapshot.map(item => (
        {
            ...item.data(),
            id: item.id
        }
    ))

export const pointsToArray = (points = []) => {
    let res = []
    points.forEach(point =>
        res.push([point._lat, point._long])
    )
    return res.length === 0 ? null : res
}

export const firebasePointsToArray = (points = []) => {
    let res = []
    points.forEach(point => res.push([point.latitude, point.longitude]))
    return res
}

export const getCurrentVersion = () => {
    let version = ''
    const metas = document.getElementsByTagName('meta')
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === 'build-version') {
            return version = metas[i].getAttribute('build-version')
        }
    }
    return version
}


export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export const getComparator = (order, orderBy) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)


export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(toLowerCase(a[0]), toLowerCase(b[0]))
        if (order !== 0) return order
        return a[1] - b[1]
    });
    return stabilizedThis.map((el) => el[0])
}

const toLowerCase = value => {
    let result = value
    try {
        result = value.toLowerCase()
    } catch (error) {
        result = value
    }
    return result
}

export const capitalize = (word) =>
    word.replace(/\b\w/g, l => l.toUpperCase())
