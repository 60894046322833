import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Avatar,
    Button,
    Card
} from '@material-ui/core'
import Post from '../components/blog/post'
import { isUserAdmin } from '../firebase/obj/user'
import { getPostsRealTime, deletePost } from '../firebase/obj/post'
import PostContainer from './postContainer'
import Notification from '../components/notification'


export const BlogContainer = ({ user, isAdmin, location }) => {
    const [openForm, setOpenForm] = useState(false)
    const [data, setData] = useState([])
    const [severity, setSeverity] = useState('success')
    const [notificationOpen, setNotificationOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('Datos guardados')

    // useEffect(() => {
    //     if (location?.state?.refresh) {
    //         getPosts()
    //             .then(posts => setData(posts))
    //             .catch(err => console.error('Error', err))
    //     }
    // }, [location])

    useEffect(() => {
        return getPostsRealTime(setData)
    }, [])


    const handleDeletePost = (id) => {
        deletePost(id).then(result => {
            setData(data.filter(e => e.id !== id))
            setSeverity('success')
            setNotificationMessage('Publicación borrada')
            setNotificationOpen(true)
        })
    }

    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setNotificationOpen(false)
    }


    if (data.length === 0)
        return (<></>)

    return (
        <Grid container direction="row" style={{ paddingBottom: 50 }}>
            <Notification severity={severity} handleNotificationClose={handleNotificationClose} autoHideDuration={1500} notificationOpen={notificationOpen} notificationMessage={notificationMessage} />
            <Grid item xs={12}>
                <Typography align="center" variant="h3" component="h2" style={{ fontWeight: 'bold', padding: '50px 0 40px 0', color: 'white' }}>
                    RECORRIDAS
                </Typography>

            </Grid>
            <Grid item xs={12}>
                {isAdmin && <Button onClick={() => setOpenForm(true)} style={{ margin: 10 }} variant="contained" color="primary">+ Nueva publicación</Button>}
            </Grid>
            <Grid item xs={12} container spacing={2}>
                {
                    data.map(item => <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                        <Post data={item} isAdmin={isAdmin} deletePost={handleDeletePost} />
                    </Grid>
                    )
                }
            </Grid>
            <PostContainer
                isAdmin={isAdmin}
                openForm={openForm}
                setOpenForm={setOpenForm}
            />
        </Grid>
    )
}

export default BlogContainer

