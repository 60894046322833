import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    toolbar: {
        marginTop: ' -10px !Important'
    },
    link: {
        textDecoration: 'none',
        color: '#000'
    },
    menuButton: {
        marginRight: 36,
        color: '#000'
    },
    menuButtonHidden: {
        display: 'none',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: 'black',
        backgroundColor: "#FFFFFF !Important",
    },
    title: {
        flexGrow: 1,
        color: '#000000',
        padding: '5px 5px 5px 20px'
    },
    container: {
        marginTop: 0,
        height: '86vh'
    },
    avatar: {
        height: '45px !Important',
        width: '45px !Important',
    },
    publicAppBar: {
        color: '#000000',
    }
}))