import React, { useState, useEffect } from 'react'
import { getPublicConfig } from '../firebase/obj/config'
import { getGallery } from '../firebase/obj/storage'
import Landing from '../components/landing'

export const LandingPageContainer = ({ user, isAdmin, location }) => {
    const [config, setConfig] = useState({})
    const [gallery, setGallery] = useState([])

    useEffect(() => {
        const _getData = () => {
            getPublicConfig()
                .then(_config => setConfig(_config))
                .catch(setConfig({}))

            getGallery().then(data => {
                //console.log('getGallery', data)
                setGallery(data)
            })
        }
        return _getData()
    }, [])

    return (
        <Landing config={config} gallery={gallery} user={user} isAdmin={isAdmin} location={location} />
    )
}

export default LandingPageContainer