import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Firebase from './firebase'
import Context from './context'
import * as serviceWorker from './serviceWorker'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

new Firebase()

ReactDOM.render(
  <Context.Provider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Context.Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.REACT_APP_ENV === 'dev') {
  //console.log('serviceWorker unregister')
  serviceWorker.unregister()
}
else if (process.env.REACT_APP_ENV === 'prod') {
  //console.log('serviceWorker register')
  serviceWorker.register()
}
