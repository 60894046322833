import firebase from 'firebase/app'
import 'firebase/firestore'
import { snapshotToArray as localSnapshotToArray } from '../../utils'
import { saveError } from './error'

export const getCollectionFiltered = ({ collection = '', filters = [], startAt = null, limit = 0, orderBy = null, orderDirection = 'asc', getSize = () => { }, snapshotToArray }) => new Promise((resolve, reject) => {
    console.log('getCollectionFiltered from ' + collection)
    let dbRef = firebase.app().firestore().collection(collection)
    filters.forEach(filter => {
        dbRef = dbRef.where(filter.field, filter.operation, filter.value)
    })
    if (orderBy) {
        dbRef = dbRef.orderBy(orderBy, orderDirection)
    }
    if (startAt) {
        dbRef = dbRef.startAt(startAt)
    }
    if (limit > 0) {
        dbRef = dbRef.limit(limit)
    }
    dbRef
        .get()
        .then(querySnapshot => {
            getSize(querySnapshot.size)
            if (snapshotToArray)
                return resolve(snapshotToArray(querySnapshot.docs))

            return resolve(localSnapshotToArray(querySnapshot.docs))
        })
        .catch(error => reject(saveError(error, `get ${collection} Filtered`)))
})

export const getDataCollection = (collection) => {
    console.log('getDataCollection from ' + collection)
    return new Promise((resolve, reject) =>
        firebase.app().firestore().collection(collection)
            .get()
            .then(querySnapshot => resolve(localSnapshotToArray(querySnapshot.docs)))
            .catch(error => reject(saveError(error, `get ${collection}`)))
    )
}


export const getDocument = (id, collection) => new Promise((resolve, reject) => {
    console.log('getDocument from ' + collection)
    return firebase.app().firestore().collection(collection)
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists)
                resolve(doc.data())
            else
                reject({ message: 'No se encontró registro en ' + collection })
        })
        .catch(error => reject(saveError(error, `get${collection}Document`)))
}
)

export const saveDocument = (doc, collection, id = null) => new Promise((resolve, reject) => {
    console.log('saveDocument to ' + collection)
    if (id) {
        doc.id = id
        firebase.app().firestore().collection(collection).doc(id).set({ ...doc })
            .then(resolve({ message: 'Documento ingresado en ' + collection, id, ...doc }))
            .catch(error => reject(saveError(error, 'saveDocument en ' + collection)))

    } else {
        firebase.app().firestore().collection(collection).add({
            ...doc,
        })
            .then(docRef => {
                firebase.app().firestore().collection(collection).doc(docRef.id).update({
                    ...doc,
                    id: docRef.id
                })
                    .then(resolve({ ...doc, message: 'Documento ingresado en ' + collection, id: docRef.id }))
                    .catch(error => reject(saveError(error, 'saveDocument > updateId ' + collection)))
            })
            .catch(error => reject(saveError(error, 'saveDocument en ' + collection)))
    }
})

export const updateDocument = (doc, collection) => new Promise((resolve, reject) => {
    console.log('updateDocument to ' + collection)
    return firebase.app().firestore().collection(collection).doc(doc.id).update({
        ...doc
    })
        .then(resolve({ message: 'Documento modificado en ' + collection }))
        .catch(error => reject(saveError(error, 'updateDocument en ' + collection)))
}

)


export const deleteDocument = (id, collection) => new Promise((resolve, reject) => {
    console.log('deleteDocument in ' + collection)
    firebase.app().firestore().collection(collection).doc(id).delete()
        .then(resolve({ message: collection + ' Document borrado' }))
        .catch(reject({ message: 'Error al borrar documento en ' + collection }))
})