import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { toDate } from '../../utils/dateFunctions'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: 5,
      margin: 5,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 5
      },
      "& .MuiInputBase-input": {
        padding: 5.5,
        paddingLeft: 0
      }
    }
  }
});


export default function DateField({ date, setDate = () => { }, label = "Fecha", id = "idDate", type = "datetime-local", inputVariant = "standard" }) {
  const classes = useStyles()
  const isValidDate = d => toDate(d) instanceof Date && !isNaN(d)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        inputVariant={inputVariant}
        variant="inline"
        format="dd/MM/yyyy"
        margin="none"
        id={id}
        label={label}
        value={isValidDate(date) ? toDate(date) : ''}
        onChange={setDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  )
}
