import React, { useState } from 'react'
import { useStyles } from './styles'
import {
    AppBar,
    Toolbar,
    Avatar,
    Typography,
    Container,
    IconButton,
    Grid,
    Box,
    Menu,
    MenuItem,
    Tooltip,
    Button
} from '@material-ui/core'
import { Link } from '@reach/router'
import truckLogo from '../../img/servitec.png'

const pages = [''] // ['Products', 'Pricing', 'Blog']

export const PublicLayout = ({ children, user }) => {
    const classes = useStyles()
    const [anchorElNav, setAnchorElNav] = useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }


    return (
        <div className={classes.root}>
            <AppBar color="default" elevation={0} className={classes.publicAppBar}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={6}>
                                <Link to='/' className={classes.link} onClick={handleCloseNavMenu}>
                                    <Grid container row="true">
                                        <Avatar alt="Servi Tec" variant="square" src={truckLogo} className={classes.avatar} />
                                        <Typography component="h1" variant="h6" noWrap className={classes.title}>
                                            {'SERVI-TEC'}
                                        </Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">
                                <Grid item>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', } }}>
                                        {pages.map((page) => (
                                            <Button
                                                key={page}
                                                onClick={handleCloseNavMenu}
                                                sx={{ my: 2, display: 'block' }}
                                                variant="text"
                                            >
                                                {page}
                                            </Button>
                                        ))}
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ flexGrow: 0 }}>
                                        <Link to='/login' className={classes.link} onClick={handleCloseNavMenu}>
                                            <IconButton onClick={handleCloseNavMenu} sx={{ p: 0 }}>
                                                <Avatar alt={user?.displayName} src={user?.imageUrl} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <main className={classes.content}>
                <Container maxWidth={false} className={classes.container} disableGutters={true}>
                    {children}
                </Container>
            </main>
        </div>
    )
}

export default PublicLayout