import firebase from 'firebase/app'
import 'firebase/firestore'
import { saveError } from './error'

export const getConfig = () => {
   return new Promise((resolve, reject) => {
      firebase.app().firestore().collection('config')
         .doc('config')
         .get()
         .then(conf => resolve(conf.data()))
         .catch(error => reject(saveError(error, 'getConfig')))
   })
}

export const getPublicConfig = () => {
   return new Promise((resolve, reject) => {
      firebase.app().firestore().collection('publicConfig')
         .doc('config')
         .get()
         .then(conf => resolve(conf.data()))
         .catch(error => reject(saveError(error, 'getConfig')))
   })
}

export const updateConfig = config => new Promise((resolve, reject) => {
   firebase.app().firestore().collection('config').doc('config').update({
      ...config
   })
      .then(resolve('success'))
      .catch(error => reject(saveError(error, 'updateConfig')))
})