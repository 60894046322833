import FirebaseApp from 'firebase/app'
import 'firebase/firestore'
import 'firebase/messaging'
import logo from '../img/logo192.png'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const initializeMessaging = (app) => {
  if (("Notification" in window)) {
    if (Notification.permission === "granted") {
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = app.messaging()
      messaging.getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
        .then(currentToken => {
          if (currentToken) {
            console.log('currentToken', currentToken)
          } else {
            console.log('can not get token')
          }
        })

      messaging.onMessage((payload) => {
        console.log('Message received. ', payload)
        // const { title = '', body = '', icon } = payload.data
        // const notificationOptions = {
        //   body: 'Mensaje: ' + body,
        //   icon: icon || logo
        // }

        // const notification = new Notification(title, { body, icon: icon || logo })
        // notification.onclick = () => {
        //   notification.close()
        //   window.parent.focus()
        // }

        // /* eslint-disable-next-line no-restricted-globals */
        // return self.registration.showNotification(title, notificationOptions)
      })
    }
  }
}
class Firebase {
  constructor() {
    if (!FirebaseApp.apps.length) {
      const app = FirebaseApp.initializeApp(config)
      initializeMessaging(app)
    }
  }
}

export default Firebase