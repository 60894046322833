import React, { lazy, Suspense } from 'react'
import { Location, Router } from '@reach/router'
import Context from './context'
import SplashPage from './pages/splashPage'
import LoginPage from './pages/loginPage'
import LandingPage from './pages/landingPage'
import PublicLayout from './components/layout/public'
const Layout = lazy(() => import('./components/layout'), 'default')
const ConfigPage = lazy(() => import('./pages/configPage'), 'default')
const ClientsPage = lazy(() => import('./pages/clientsPage'), 'default')
const WorkPage = lazy(() => import('./pages/workPage'), 'default')
const NewPostPage = lazy(() => import('./pages/newPostPage'), 'default')
const SchedulePage = lazy(() => import('./pages/schedulePage'), 'default')
const CoverImagesPage = lazy(() => import('./pages/coverImagesPage'), 'default')

const userAccess = ({ user, isAdmin, isAuth, config, location }) => {
  if (user && isAuth) {
    return (
      <Layout>
        <Router location={location}>
          <WorkPage path='/' />
          <WorkPage path='/work' location={location} />
          <LandingPage path='/home' user={user} isAdmin={isAdmin} location={location} />
          <ConfigPage path='/config' />
          <ClientsPage path='/clients' />
          <NewPostPage path='/newPost' user={user} isAdmin={isAdmin} />
          <SchedulePage path='/schedule' isAdmin={isAdmin} />
          <CoverImagesPage path='/coverImages' isAdmin={isAdmin} />
        </Router>
      </Layout>
    )
  } else {
    return (
      <PublicLayout>
        <Router>
          <LandingPage path='/' />
          <LoginPage path='/login' />
        </Router>
      </PublicLayout>
    )
  }
}

function App() {
  return (
    <Suspense fallback={<SplashPage />}>
      <Location>
        {({ location }) => (
          <Context.Consumer>
            {
              ({ isAuth, user, isAdmin, config }) =>
                userAccess({ user, isAdmin, isAuth, config, location })
            }
          </Context.Consumer>
        )}
      </Location>
    </Suspense>
  )
}

export default App