import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import logo from '../../img/servitec.png'

const useStyles = makeStyles(theme => ({
  input: {
    margin: 5,
  },
  button: {
    width: '100%',
    margin: 5
  },
  logo: {
    width: 200,
    height: 'auto'
  }
}))


export default function LoginForm({ signInWithEmailAndPassword }) {
  const classes = useStyles()
  const [email, setEmail] = useState()
  const [pass, setPass] = useState()
  const [error, setError] = useState()

  const handleSignIn = () => {
    if (!pass) {
      setError('Ingrese contraseña')
      return
    } else if (!email) {
      setError('Ingrese correo electrónico')
      return
    }

    signInWithEmailAndPassword(email, pass)
      .then(() => setError(''))
      .catch(err => {
        let _error = err.message
        if (err.code === 'auth/wrong-password') {
          _error = 'La contraseña no es válida'
        } else if (err.code === 'auth/invalid-email') {
          _error = 'La dirección de correo no es correcta'
        } else if (err.code === 'auth/user-not-found') {
          _error = 'Nombre de usuario incorrecto'
        }

        setError(_error)
      })
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item >
        <img src={logo} className={classes.logo} alt="Servi Tec" />
      </Grid>
      <TextField className={classes.input} onChange={e => setEmail(e.target.value)} label="Usario" variant="outlined" placeholder="Usario" />
      <TextField className={classes.input} onChange={e => setPass(e.target.value)} label="Contraseña" variant="outlined" placeholder="Contraseña" type="password" />
      <Typography variant="caption" color="error" >{error}</Typography>
      <Button onClick={handleSignIn} variant="contained" className={classes.button} color="primary" >Ingresar</Button>
    </Grid>
  )
}