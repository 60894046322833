import firebase from 'firebase/app'
import 'firebase/auth'
import { saveError } from './obj/error'
import { navigate } from '@reach/router'

export const loginWithGoogle = () => new Promise((res, rej) => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithPopup(provider)
        .then(result => res(result.user))
        .catch(error => rej(error))
})

export const loginWithFacebook = () => new Promise((res, rej) => {
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase.auth().signInWithPopup(provider)
        .then(result => res(result.user))
        .catch(error => rej(error))
})

/**
 * @param email
 * @param password
 * @return promise
 */
export const signInWithEmailAndPassword = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => navigate("/"))


export const logOut = (e) =>
    firebase.auth().signOut()
        .then(() => navigate("/"))
        .catch(error => console.error(saveError(error, 'userContainer logOut')))