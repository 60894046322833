import firebase from 'firebase/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'
import {
    getCollectionFiltered,
    getDocument,
    deleteDocument
} from './abm'

const collection = 'users'

export default class User {
    constructor() {
        this.active = true
        this.name = ''
        this.email = ''
        this.admin = false

    }
}

export const getCurrentUser = () => firebase.auth().currentUser ? firebase.auth().currentUser : {}

export const getUsersRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy('name', 'asc')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })

export const getTaxiDriversRealTime = (setState = () => { }) => {
    return firebase.app().firestore().collection(collection)
        .where('taxiDriver', '==', true)
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })
}

export const getUsersFiltered = (field = null, value = null, startAt = null, limit = 0, orderBy = null, getSize = () => { }) =>
    getCollectionFiltered({ collection, field, value, startAt, limit, orderBy, getSize })


export const getUser = id => getDocument(id, collection)

export const deleteUser = id => deleteDocument(id, collection)

export const updateUser = (user) => new Promise((resolve, reject) =>
    firebase.app().firestore().collection(collection).doc(user.email).update({
        ...user
    })
        .then(resolve({ message: 'Usuario modificado' }))
        .catch(error => reject(saveError(error, 'updateUser')))
)

export const saveUser = (user) => new Promise((resolve, reject) => {
    console.log('saveUser')
    user.id ?
        firebase.app().firestore().collection(collection).doc(user.id).update({
            ...user
        })
            .then(resolve({ message: 'Usuario modificado' }))
            .catch(error => reject(saveError(error, 'saveUser > update')))
        :
        firebase.app().firestore().collection(collection)
            .doc(user.email)
            .set(user)
            .then(() => resolve({ message: 'Usuario ingresado' }))
            .catch(error => reject(saveError(error, 'saveUser > add')))
})

export const isUserAdmin = (user) =>
    new Promise((resolve, reject) => {
        //console.log('isUserAdmin?')
        if (!user) resolve(false)
        if (!user.email) resolve(false)

        let docRef = firebase.app().firestore().collection(collection).doc(user.email)
        docRef.get()
            .then(doc => {
                !doc.exists && resolve(false)
                resolve(doc.data().admin)
            })
            .catch(error => resolve(false))
    })



