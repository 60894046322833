import firebase from 'firebase/app'
import "firebase/storage"
import { saveError } from './error'

export const getGallery = async () => {
    const result = await firebase.storage().ref('gallery').listAll()
    const urlPromises = result.items.map(imageRef => imageRef.getDownloadURL())

    return Promise.all(urlPromises)
}

export const uploadImageAsString = ({ postId, filename, source, setUrl = () => { }, updateProgress = () => { }, setError = () => { } }) => new Promise((resolve, reject) => {
    //console.log('postId', postId)
    const postsRef = firebase.storage().ref(`posts/${postId}/${filename}`)
    const task = postsRef.putString(source, 'data_url')

    task.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed',
        snapshot => {
            const porcentaje = snapshot.bytesTransferred / snapshot.totalBytes * 100
            updateProgress(porcentaje)
        },
        err => {
            console.error(err, 'saveImage')
            setError(err)
            reject(err)
        },
        () => {
            task.snapshot.ref.getDownloadURL()
                .then(url => {
                    setUrl(url)
                    resolve(url)
                })
                .catch(error => {
                    console.error(error, 'saveImage > getDownloadURL')
                    setError(error)
                    reject(error)
                })
        })
})

export const uploadImagesAsString = ({ postId, sources = [], setUrls = () => { }, updateProgress = () => { }, setError = () => { } }) => {

    const urlArray = []
    let totalProgress = 0

    const setUrl = url => urlArray.push(url)
    const _updateProgress = progress => {
        totalProgress += progress
        const _progress = totalProgress / sources.length
        updateProgress(_progress)
    }

    Promise.all(sources.map((source, index) => {
        const filename = `${postId}_${index}`
        console.log()
        return uploadImageAsString({ postId, filename, source, setUrl, updateProgress: _updateProgress })
    }))
        .then(urls => setUrls(urls))
        .catch(error => setError(error))

}


// <imagenes de portada
export const getCoverImages = async () => {
    const result = await firebase.storage().ref('cover').listAll()
    const urlPromises = result.items.map(async (item) => ({ url: await item.getDownloadURL(), ref: item }))

    return Promise.all(urlPromises)
}

export const saveCoverImage = (file, setUrl = () => { }, updateProgress = () => { }) => {
    const imgName = parseInt(new Date().valueOf() * Math.random())
    const refStorage = firebase.storage().ref(`cover/${imgName}`)
    const task = refStorage.put(file)
    task.on('state_changed',
        snapshot => {
            const porcentaje = snapshot.bytesTransferred / snapshot.totalBytes * 100
            updateProgress(porcentaje)
        },
        err => {
            console.error(saveError(err, 'saveCoverImage'))
        },
        () => {
            task.snapshot.ref.getDownloadURL()
                .then(url => {
                    updateProgress(99)
                    setUrl(url)
                })
                .catch(error => console.error(saveError(error, 'saveCoverImage > getDownloadURL')))
        })
}

export const deleteCoverImage = ({ fullPath = '' }) => {
    const fileRef = firebase.storage().ref(fullPath)
    return fileRef.delete()
}

//imagenes de portada>
