import firebase from 'firebase/app'
import 'firebase/firestore'
import { saveError } from './error'

const collection = 'currencies'

export const getCurrency = (id = '2222') => {
    return new Promise((resolve, reject) => {
        firebase.app().firestore().collection(collection)
            .doc(id)
            .get()
            .then(conf => resolve(conf.data()))
            .catch(error => reject(saveError(error, 'getCurrency')))
    })
}

