import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Avatar,
    Button,
    Card
} from '@material-ui/core'
import CoverContainer from '../../containers/coverContainer'
import InfiniteCarousel from '../carousel/infiniteCarousel'
import WhatsappButton from '../whatsappButton'
import { useStyles } from './styles'
import logo from '../../img/servitec_white.jpeg'
import Map from '../../img/uyMap.png'
import Case from '../../img/case.png'
import NH from '../../img/newHolland.png'
import Footer from '../footer'
import BlogContainer from '../../containers/blogContainer'


export const LandingComponent = ({ config = {}, gallery = [], user, isAdmin, location }) => {
    const classes = useStyles()

    // console.log('LandingComponent', isAdmin)

    return (
        <Grid id="home" container direction="row" className={classes.root}>

            <Grid item xs={12} container style={{ height: '100vh' }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.carouselChildren}
                >
                    <Avatar alt="Servi Tec" src={logo} className={classes.logoAvatar} />
                </Grid>
                <CoverContainer />
            </Grid>
            <Grid item xs={12} container direction="row" className={classes.body2}>
                <BlogContainer config={config} user={user} isAdmin={isAdmin} location={location} />
            </Grid>
            <Grid item xs={12} container direction="row" alignItems="center"
                justifyContent="center" className={classes.title}>
                <Grid item xs={12}>
                    <Typography align="center" variant="h3" component="h2" style={{ fontWeight: 'bold', padding: '50px 0 0 0' }}>
                        SERVICIO AUTORIZADO
                    </Typography>
                </Grid>
                <Grid item md={6} style={{ padding: 50 }} container justifyContent="center">
                    <img src={Case} alt='Case' style={{ width: '95%', height: 'auto' }} />
                </Grid>
                <Grid item md={6} style={{ padding: 50 }} container justifyContent="center">
                    <img src={NH} alt='New Holland' style={{ width: '95%', height: 'auto' }} />
                </Grid>

            </Grid>

            <Grid item xs={12} container direction="row" className={classes.body}>
                <Grid item xs={12} >
                    <Typography align="center" variant="h3" component="h2" style={{ fontWeight: 'bold', padding: '50px 0 0 0', color: 'white' }}>
                        SOBRE NOSOTROS
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.aboutContainer}
                >
                    <Grid item xs={12} className={classes.about} >
                        <Typography component="p" className={classes.aboutText}>
                            Servi-Tec es una empresa que brinda servicios de mecanica movil, agrícola vial y forestal, a todo el país.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Especializados en mecánica  hidráulica ,eléctronica , neumática  y soluciones en soldadura a campo, servi-tec cuenta con personas capacitadas, con mas de 20 años de experiencia en el rubro.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Ofrecemos servicio a todas las marcas y modelos de maquinaria contando con autorizacion de marcas internacionales como Case y New Holland.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Estamos en constante capacitacion y actualizándonos en las nuevas tecnologías, actualmente contando con scaner original CNH, próximamente Jhon Deere y multimarca.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Asimismo poseemos especializacion en sistemas de inyección common raíl y convencional puesta a punto.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.about} >
                        <Typography component="p" className={classes.aboutText}>
                            Garantizamos un servicio de calidad en cortos lapsos de tiempo, procurando la excelencia, adaptándonos a las necesidades de nuestros clientes.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Realizamos un seguimiento de nuestros servicios, y ofrecemos orientación de compra de respuestos tanto originales como optativos o paralelos.
                        </Typography>
                        <Typography component="p" className={classes.aboutText}>
                            Se pueden contactar con nosotros por nuestras vias de comunicación las cuales estamos al pendiente, indicarnos el inconveniente e indiferentemente del mismo, apostaremos a brindar la mejor solución.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} container style={{ minHeight: 300 }}>
                <InfiniteCarousel gallery={gallery} />
            </Grid>
            <Grid item xs={12} container>
                <Grid item sm={4} style={{ padding: 20 }}>
                    <img src={Map} alter='Mapa Uruguay' style={{ height: 'auto', width: '100%' }} />
                </Grid>
                <Grid item sm={8} container direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Typography align="center" variant="h3" component="h2" style={{ fontWeight: 'bold', padding: 50, }}>
                            BRINDAMOS COBERTURA EN TODO EL PAÍS
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" className={classes.footer}>
                <Footer config={config} />
            </Grid>
            <WhatsappButton number={config.whatsapp || '+59898237319'} />
        </Grid>
    )
}

export default LandingComponent
