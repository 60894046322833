import { minutesToHours } from "date-fns"

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
}

export const isWeekend = date => {
    const dt = new Date(date)
    if (dt.getDay() === 6 || dt.getDay() === 0) {
        return true
    }
    return false
}

export const isHoliday = dates => {
    const now = new Date()
    let result = false
    dates.forEach(day => {
        day = timestampToDate(day)
        console.log('day ', day.getDay(), now.getDay())
        console.log('month ', day.getMonth(), now.getMonth())
        if (day.getDay() === now.getDay() && day.getMonth() === now.getMonth())
            result = true
    })
    return result
}

export const timestampToDate = ts => new Date(ts.seconds * 1000)

/**
* @return {string} YYYY-MM-DDTHH:MM
*/
export const toISOString = date =>
    date.toISOString ?
        date.toISOString().split('.')[0].substring(0, 16)
        :
        timestampToDate(date).toISOString().split('.')[0].substring(0, 16)
//'YYYY-MM-DDTHH:MM:SS'

export const toDate = date => {
    const { seconds } = date
    if (seconds) {
        return timestampToDate(date)
    } else {
        return date
    }
}

// Fecha en formato mes sistema, Enero = 0
export const toYYYYMMString = date => `${date.getFullYear()}${date.getMonth()}`


export const dateFormat = (date, format) => {
    const result = date
    if (format === 'HH:mm') {
        return date.toLocaleTimeString('en-GB').slice(0, 5)
    }
    if (format === 'HH:mm:ss') {
        return date.toLocaleTimeString('en-GB')
    }
    if (format === 'YYYY-MM-dd HH:mm') {
        const HHmm = date.toLocaleTimeString('en-GB').slice(0, 5)
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${HHmm}`
    }
    return result
}

export const dateDiff = (a, b) => Math.abs(a - b) / 36e5

export const dateDiffToString = (a, b, format) => {
    let diff = Math.abs(a - b)

    let ms = diff % 1000
    diff = (diff - ms) / 1000
    let s = diff % 60
    diff = (diff - s) / 60
    let m = diff % 60
    diff = (diff - m) / 60
    let h = diff

    let ss = s <= 9 && s >= 0 ? `0${s}` : s
    let mm = m <= 9 && m >= 0 ? `0${m}` : m
    let hh = h <= 9 && h >= 0 ? `0${h}` : h

    if (format === 'HH:mm') {
        return hh + ':' + mm
    }
    if (format === 'HH:mm:ss') {
        return hh + ':' + mm + ':' + ss
    }

    return hh + ':' + mm + ':' + ss
}

//// arr example ['00:40', '08:10']
export const sumHours = (arr = []) => {
    let total = '00:00'
    let hours = 0
    let min = 0

    arr.forEach(item => {
        hours += parseInt(item.split(':')[0])
        min += parseInt(item.split(':')[1])
    })

    const h = Math.floor(min / 360)
    const m = Math.floor(min % 60)
    console.log(min, h, m)
    hours += h
    min = m

    let mm = min <= 9 && min >= 0 ? `0${min}` : min
    let hh = hours <= 9 && hours >= 0 ? `0${hours}` : hours

    total = `${hh}:${mm}`

    return total
}

const DATE_UNITS = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
}

// restamos el tiempo actual al que le pasamos por parámetro
// lo dividimos entre 1000 para quitar los milisegundos
const getSecondsDiff = timestamp => (Date.now() - timestamp) / 1000


const getUnitAndValueDate = (secondsElapsed) => {
    // creamos un for of para extraer cada unidad y los segundos en esa unidad del diccionario
    for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
        // si los segundos que han pasado entre las fechas es mayor a los segundos
        // que hay en la unidad o si la unidad es "second"...
        if (secondsElapsed >= secondsInUnit || unit === "second") {
            // extraemos el valor dividiendo el tiempo que ha pasado en segundos
            // con los segundos que tiene la unidad y redondeamos la unidad
            // ej: 3800 segundos pasados / 3600 segundos (1 hora) = 1.05 horas
            // Math.floor(1.05) -> 1 hora
            // finalmente multiplicamos por -1 ya que necesitamos
            // la diferencia en negativo porque, como hemos visto antes,
            // así nos indicará el "Hace ..." en lugar del "Dentro de..."
            const value = Math.floor(secondsElapsed / secondsInUnit) * -1
            // además del valor también tenemos que devolver la unidad
            return { value, unit }
        }
    }
}

export const getTimeAgo = timestamp => {
    // creamos una instancia de RelativeTimeFormat para traducir en castellano
    const rtf = new Intl.RelativeTimeFormat()
    // recuperamos el número de segundos de diferencia entre la fecha que pasamos
    // por parámetro y el momento actual
    const secondsElapsed = getSecondsDiff(timestamp)
    // extraemos la unidad de tiempo que tenemos que usar
    // para referirnos a esos segundos y el valor
    const { value, unit } = getUnitAndValueDate(secondsElapsed)
    // formateamos el tiempo relativo usando esos dos valores
    return rtf.format(value, unit)
}

