import firebase from 'firebase/app'
import 'firebase/firestore'
import {
  getDocument,
  saveDocument,
} from './abm'

const collection = 'topics'

export default class Topic {
  constructor() {
    this.token = ''
    this.user = ''
    this.topic = ''
  }
}


export const getTopic = token => getDocument(token, collection)

export const saveTopic = topic => saveDocument(topic, collection, topic.token)

