import firebase from 'firebase/app'
import 'firebase/firestore'
import { detectDeviceType } from '../../utils/detectNavigator'


export const saveError = (error, method = '') => {
    try {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(u => {

                const err = {
                    code: error.code ? error.code : '',
                    error: error.message ? error.message : '',
                    date: new Date(),
                    device: detectDeviceType(),
                    user: u ? u.email : '',
                    method
                }

                firebase.app().firestore().collection("errors")
                    .doc()
                    .set(err)
                    .then(resolve(error))
                    .catch(e => reject(e))
            })
        })
    } catch (error) {
        console.error(error)
    }
    return error
}