import { makeStyles } from '@material-ui/core/styles'
import splash from '../../img/splash.png'

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    carouselChildren: {
        width: '100%',
        paddingTop: 250,
        position: 'absolute',
        color: 'white',
        zIndex: 10
    },
    logoAvatar: {
        margin: 'auto',
        height: '200px !Important',
        width: '200px !Important',
    },
    body: {
        minHeight: '100vh',
        backgroundColor: '#7D7D7D',
        backgroundImage: `url(${splash})`,
        backgroundPosition: '50 % 141.594px',
        backgroundRepeat: 'repeat'
    },
    body2: {
        backgroundColor: '#7D7D7D',
        backgroundImage: `url(${splash})`,
        backgroundPosition: '50 % 141.594px',
        backgroundRepeat: 'repeat'
    },
    footer: {
        backgroundColor: '#7D7D7D',
        backgroundImage: `url(${splash})`,
        backgroundPosition: '50 % 141.594px',
        backgroundRepeat: 'repeat'
    },
    card: {
        margin: 20,
        padding: 10,
        width: 200,
        height: 200
    },
    aboutContainer: {
        padding: '50px 50px 80px 50px'
    },
    about: {
        color: '#FFFFFFFF',
        padding: 6
    },
    aboutText: {
        fontSize: 20.5,
        textAlign: 'justify'
    }
}))