import React from 'react'
import {
    Grid,
    Typography,
    Avatar,
    Button,
    Card,
    Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
    }
}))


export const InfiniteCarousel = ({ gallery = [], itemPerTime = 3, partialVisible = true, slidesToSlide = 1, autoPlay = true }) => {
    const classes = useStyles()
    const deviceType = 'desktop'

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    //console.log('gallery', gallery)
    return (
        <Carousel
            // swipeable={true}
            // draggable={true}
            // showDots={false}
            //responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            // infinite={true}
            // autoPlay={deviceType !== "mobile" ? true : false}
            // autoPlaySpeed={1}
            // centerMode={false}
            // keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={1000}
            // containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={deviceType}
            // slidesToSlide={1}
            //dotListClass="custom-dot-list-style"
            //itemClass="carousel-item-padding-40-px"
            additionalTransfrom={0}
            arrows
            autoPlay={autoPlay}
            autoPlaySpeed={2000}
            centerMode={false}
            className={classes.root}
            containerClass=""
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            partialVisible={partialVisible}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: itemPerTime,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={slidesToSlide}
            swipeable
        >
            {
                gallery.map((element, index) =>
                    <Grid item key={index} style={{ height: 300, width: '100%', objectFit: 'cover' }}>
                        <Box
                            component="img"
                            sx={{
                                height: 300,
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            alt={`Item ${index}`}
                            src={element}
                        />
                    </Grid>
                )
            }
        </Carousel>
    )
}

export default InfiniteCarousel
