import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Login from '../containers/loginContainer'
import wallpaper from '../img/0001.jpg'

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        flexGrow: 1,
    },
    loginContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        padding: 30,
        borderRadius: 10,
        border: '1px solid black',
    },
    heroContainer: {
        height: '100vh',
        backgroundImage: `url(${wallpaper})`,
        backgroundPosition: 'center',
        width: '100vw',
    },
    heroContainer1: {
        height: '100vh',
        backgroundImage: `url(${wallpaper})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
    },
}))

export const LoginPage = () => {
    const classes = useStyles()

    return (
        <Grid
            className={classes.heroContainer1}
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid className={classes.loginContainer} >
                <Login />
            </Grid>

        </Grid>
    )
}

export default LoginPage