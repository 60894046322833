import React, { useEffect, useState } from 'react'
import {
    Grid,
    TextField,
    Button,
    Typography,
    LinearProgress
} from '@material-ui/core'
import { useStyles } from './styles'
import Dialog from '../../dialog'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import DateField from '../../dateField'

const voidF = () => { }

export const PostForm = ({ isAdmin, openForm, setOpenForm = voidF, savePost = voidF }) => {
    const classes = useStyles()
    const [post, setPost] = useState({ date: new Date() })
    const [openCamera, setOpenCamera] = useState(false)
    const [dataUris, setDataUris] = useState([])
    const [error, setError] = useState('')
    const [updateProgressImg, setUpdateProgressImg] = useState(0)
    const isFullscreen = false

    useEffect(() => {
        if (!openForm) {
            setPost({ date: new Date() })
            setDataUris([])
            setError('')
            setUpdateProgressImg(0)
            setOpenCamera(false)
        }
    }, [openForm])

    const handleSave = () => {
        //console.log('handleSave', post, isAdmin)

        if (!isAdmin)
            return

        if (!post.date)
            post.date = new Date()

        if (validate())
            savePost({ ...post, images: dataUris }, setUpdateProgressImg, setError)
    }

    const validate = () => {
        let _error = null

        if (dataUris.length === 0)
            _error = 'Seleccione o tome una foto'

        if (!post.description)
            _error = 'Ingrese una descripción'

        if (!post.locationName)
            _error = 'Ingrese una ubicación'


        setError(_error)
        return _error ? false : true
    }

    const handleTakePhotoAnimationDone = (dataUri) => {
        //console.log('takePhoto', dataUri)
        setDataUris([...dataUris, dataUri])
        setOpenCamera(false)
    }

    const validateImg = file => file && file.name?.toLowerCase().match(/(.*?)\.(jpg|bmp|jpeg|png)$/)


    const onChangeImg = (e) => {

        const files = e.target.files
        const readers = []

        // Verifico si hay archivos seleccionados
        if (!files.length) return

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (validateImg(file)) {
                const readerFile = new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = () => reject(reader)
                    // convierte la imagen blob a una cadena en base64
                    reader.readAsDataURL(file)
                })
                readers.push(readerFile)
            }
        }

        Promise.all(readers).then((values) => {
            setDataUris([...dataUris, ...values])
        })

        //const description = `${Date.now()}.${file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)}`
        //prod.subirImagenProducto(file, descripcion, this.actualizarImgProgress, this.getImgUrl)
    }

    // if (!isAdmin)
    //     return (<></>)

    return (
        <Grid>
            <Dialog disableBackdropClick={true} message={'Tomar foto'} fullScreen={true} fullWidth={false} maxWidth={'lg'} open={openCamera} setOpen={setOpenCamera} acceptText={null} cancelText={null} >
                <Camera
                    onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                    isFullscreen={isFullscreen}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                />
            </Dialog>
            <Dialog disableBackdropClick={true} message={'Nueva publicación'} fullWidth={true} maxWidth={'xs'} open={openForm} setOpen={setOpenForm} acceptText={null} cancelText={null} >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6} style={{ marginTop: 3, width: '100%' }}>
                        <DateField inputVariant="outlined" label={'Fecha'} date={post.date || new Date()} setDate={(date) => setPost({ ...post, date })} type="date" />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: 3, width: '100%' }}>
                        <TextField type="text" name="locationName" id="locationName" variant="outlined" value={post.locationName || ''} onChange={e => setPost({ ...post, locationName: e.target.value })} label="Ubicación" size="small" className={classes.inputTxt} required />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 3, width: '100%' }} >
                        <TextField multiline={true} rows="3" autoComplete="off" name="description" id="description" variant="outlined" value={post.description || ''} onChange={e => setPost({ ...post, description: e.target.value })} label="Descripción" placeholder="Descripción de la foto, lugar y trabajo" size="small" className={classes.inputTxt} required />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 3, width: '100%' }} container >


                        {
                            dataUris.map(dataUri =>
                                <span key={dataUri} style={{ padding: 10 }}>
                                    <img alt={dataUri} src={dataUri} style={{ width: '100%', height: 'auto' }} />
                                    <Button style={{ marginTop: 0, width: '100%' }} variant="outlined" color="secondary" onClick={() => setDataUris(dataUris.filter(d => d !== dataUri))}>
                                        Borrar foto
                                    </Button>
                                </span>
                            )
                        }

                        <Grid item xs={5} style={{ padding: 3, width: '100%' }} >
                            <Button className={classes.inputTxt} variant="outlined" color="primary" onClick={() => setOpenCamera(true)}>
                                Tomar foto
                            </Button>
                        </Grid>
                        <Grid item xs={7} style={{ padding: 3, width: '100%' }} >
                            <Button component="label" className={classes.inputTxt} variant="outlined" color="primary" >
                                Seleccionar fotos
                                <input type="file" multiple="multiple" hidden onChange={onChangeImg} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 3, width: '100%' }} >
                        {
                            updateProgressImg > 0 && <LinearProgress variant="determinate" value={updateProgressImg} />
                        }
                    </Grid>
                    <Grid item xs={12} style={{ padding: 3, width: '100%' }} >
                        {
                            error && <Typography className={classes.inputTxt} color="secondary">Error: {error}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 3, width: '100%' }} >
                        <Button className={classes.inputTxt} variant="contained" color="primary" onClick={handleSave}>
                            Subir publicación
                        </Button>
                    </Grid>
                </Grid>
            </Dialog >
        </Grid >
    )
}

export default PostForm