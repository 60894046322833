import React from 'react'
import { animations } from 'react-animation'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Link, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    WhatsappButtonFixed: {
        backgroundColor: '#25d366 !important',
        color: '#fcfcfc!important',
        borderColor: '#fafafa00!important',
        position: 'fixed',
        right: '20px!important',
        bottom: '20px!important',
        width: '60px',
        height: '60px',
        borderRadius: '50 % !important',
        zIndex: 100,
        "&:hover": {
            backgroundColor: '#128c7e !important',
        }
    },
    WhatsappButtonFixedIcon: {
        fontSize: 50
    }
}))

export default function WhatsAppButton({ number = null }) {
    const classes = useStyles()
    const href = `https://api.whatsapp.com/send?phone=${number}&text=Hola%20Servi%20Tec`

    return (
        <React.Fragment>
            {
                number &&
                <Link
                    target="_blank"
                    underline="none"
                    href={href}
                >
                    <Avatar className={classes.WhatsappButtonFixed} style={{ animation: animations.popIn }}>
                        <Tooltip title="Contacto" >
                            <WhatsAppIcon className={classes.WhatsappButtonFixedIcon} />
                        </Tooltip>
                    </Avatar>
                </Link>

            }
        </React.Fragment>
    )
}

/* <a style={{ animation: animations.popIn }} target="_blank" className={classes.WhatsappButtonFixed} href={`https://api.whatsapp.com/send?phone=${number}&text=Hola!`}>

                    <Icon className="WhatsappButtonFixedIcon" size={42} icon={whatsapp} />}
                </a> */