import React from 'react'
import Carousel from 'fade-carousel' // https://www.npmjs.com/package/fade-carousel
import {
    Grid,
} from '@material-ui/core'

// import { useStyles } from './styles'
// import clsx from 'clsx'

// export const Carousel = () => {
//     const classes = useStyles()

//     return (
//         <div className={classes.root}>
//             <div className={clsx(classes.slide, classes.slide1)}></div>
//             <div className={clsx(classes.slide, classes.slide2)}></div>
//             <div className={clsx(classes.slide, classes.slide3)}></div>
//             <div className={clsx(classes.slide, classes.slide4)}></div>
//         </div>
//     )
// }

export const CarouselComponent = ({ items = [] }) => {

    const divStyle = {
        height: "100%",
        width: "100%",
        backgroundColor: '#f2f2f2'
    }

    return (
        <Grid container style={{ maxWidth: '100vw', maxHeight: '100vh' }}>
            <Carousel divStyle={divStyle} delay={4000} mode={"fade"} >
                {items.map((item, index) => (
                    <div key={index} style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${item.url})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                    }} />
                ))}
            </Carousel>
        </Grid>
    )
}

export default CarouselComponent

/*
<div key={index} style={imageStyle}>
                        <img
                            src={item.url}
                            style={{ height: "100%", width: "auto", backgroundSize: 'cover' }}
                            alt="asdada"
                            onClick={hello}
                        />
                    </div>
                    */