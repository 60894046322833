import React, { useEffect, useState } from 'react'
import {
    Card,
    Avatar,
    CardHeader,
    CardMedia,
    CardContent,
    Typography,
    IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import logo from '../../../img/servitec_white.jpeg'
import { getTimeAgo, toDate } from '../../../utils/dateFunctions'
import Dialog from '../../dialog'
import InfiniteCarousel from '../../carousel/infiniteCarousel'

export const Post = ({ data, isAdmin, deletePost }) => {
    const [openDialog, setDialogOpen] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('')

    const handleDelete = () => {
        setDialogMessage('¿Desea borrar la publicación?')
        setDialogOpen(true)
    }

    return (
        <Card style={{ margin: 10, width: '100%' }}>
            <Dialog open={openDialog} setOpen={setDialogOpen} message={dialogMessage} acceptChanges={() => deletePost(data.id)} />
            <CardHeader
                avatar={
                    <Avatar alt="SERVI-TEC" src={logo} />
                }
                action={
                    isAdmin &&
                    <IconButton aria-label="settings" onClick={handleDelete}>
                        <DeleteIcon color="secondary" />
                    </IconButton>
                }
                title={data.locationName}
                subheader={getTimeAgo(toDate(data.date).getTime())}
            />

            {
                data.images?.length === 1 ?
                    data.images?.map(img =>
                        <CardMedia
                            key={img}
                            component="img"
                            height="194"
                            image={img}
                            alt="Photo"
                        />
                    )
                    :
                    <InfiniteCarousel gallery={data.images} itemPerTime={1} partialVisible={false} slidesToSlide={1} autoPlay={false} />
            }
            {
                data.img &&
                <CardMedia
                    component="img"
                    height="194"
                    image={data.img}
                    alt="Photo"
                />
            }

            <CardContent>
                <Typography variant="body2" color="textSecondary">
                    {data.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default Post

