import React, { createContext, useState } from 'react'
import firebase from 'firebase/app'
import { getConfig } from './firebase/obj/config'
import { getCurrency } from './firebase/obj/currency'
import { isUserAdmin } from './firebase/obj/user'
import { getTopic, saveTopic } from './firebase/obj/notificationTopic'
require('firebase/auth')

export const Context = createContext()

const suscribeToTopic = (user, setSuscribedTotopic) => {
    if (("Notification" in window))
        if (Notification.permission === "granted")
            firebase.messaging()
                .getToken()
                .then((token) => {
                    getTopic(token).then(data => {
                        console.log('existe suscripcion del token')
                        // console.log('messaging token', token)
                    })
                        .catch(err => {
                            if (err.message === 'No se encontró registro en topics') {
                                saveTopic({
                                    id: token,
                                    token,
                                    topic: 'all',
                                    userEmail: user.email,
                                    userId: user.uid
                                }).then(() => {
                                    console.log('Se registro al topic all')
                                    setSuscribedTotopic(true)
                                })
                            }
                        })

                    return token;
                })
}


const Provider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false)
    const [isAdmin, setAdmin] = useState(false)
    const [user, setUser] = useState(null)
    const [config, setConfig] = useState(null)
    const [usdCurrency, setUsdCurrency] = useState(null)
    const [suscribedToTopic, setSuscribedTotopic] = useState(false)

    const value = {
        isAuth,
        isAdmin,
        user,
        config,
        usdCurrency,
        activateAuth: firebase.auth().onAuthStateChanged(user => {
            setUser(user)
            if (user != null) {
                setIsAuth(true)
                !config && getConfig().then(_config => setConfig(_config))
                !usdCurrency && getCurrency().then(usd => setUsdCurrency(usd))
                isUserAdmin(user).then(adm => setAdmin(adm))
                window.sessionStorage.setItem('user', user.displayName)
                !suscribedToTopic && suscribeToTopic(user, setSuscribedTotopic)
            } else {
                setIsAuth(false)
                setAdmin(false)
                setConfig(null)
                window.sessionStorage.setItem('user', null)
            }
        }),
    }

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export default {
    Provider,
    Consumer: Context.Consumer
}