import React from 'react'
import {
    Grid,
    Typography,
    IconButton,
    Link,
    Button,
    Card,
    Box,
    Divider
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import EmailIcon from '@material-ui/icons/Email'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'



const useStyles = makeStyles(theme => ({
    root: {
        padding: 60,
        color: '#FFFFFF'
    },
    blockContainer: {
        marginBottom: 30
    },
    title: {
    },
    divider: {
        width: '96%',
        background: '#FFFFFF',
    },
    iconButton: {
        color: '#FFFFFF',
        justifyContent: 'flex-start'
    },
    textButton: {
        paddingLeft: 20,
    },
    socialContainer: {
        paddingTop: 20
    },
    piecesContainer: {
        paddingTop: 20
    }
}))

export const Footer = ({ config = {} }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root} >
            <Grid item xs={12} md={6} className={classes.blockContainer}>
                <Typography className={classes.title}>
                    Contáctenos
                </Typography>
                <Divider className={classes.divider} />
                <Grid item xs={12} container >
                    <Grid item xs={12} className={classes.socialContainer} >
                        <IconButton href={config.facebook} target="_blank" aria-label="Facebook" className={classes.iconButton} >
                            <FacebookIcon fontSize='large' />
                            <Typography className={classes.textButton}>Facebook</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <IconButton href={config.instagram} target="_blank" aria-label="Instagram" className={classes.iconButton}>
                            <InstagramIcon fontSize='large' />
                            <Typography className={classes.textButton}>Instagram</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <IconButton href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%20Servi%20Tec`} target="_blank" aria-label="Whatsapp" className={classes.iconButton}>
                            <WhatsAppIcon fontSize='large' />
                            <Typography className={classes.textButton}>{config.phone || '098237319'}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <IconButton href={`mailto:${config.email}`} target="_blank" aria-label="Email" className={classes.iconButton} >
                            <EmailIcon fontSize='large' />
                            <Typography className={classes.textButton}>{config.email || 'Correo electrónico'}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <IconButton href={`tel:${config.whatsapp}`} target="_blank" aria-label="Phone" className={classes.iconButton}>
                            <PhoneAndroidIcon fontSize='large' />
                            <Typography className={classes.textButton}>{config.phone || '098237319'}</Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.blockContainer}>
                <Typography className={classes.title}>
                    Repuestos oficiales
                </Typography>
                <Divider className={classes.divider} />
                <Grid item xs={12} container className={classes.piecesContainer}>
                    {
                        config.pieces?.map((el, index) =>
                            <Grid key={index} item xs={12} >
                                <IconButton href={el.url} target="_blank" aria-label={el.description} className={classes.iconButton}>
                                    <Typography>{el.description}</Typography>
                                </IconButton>
                            </Grid>)
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer