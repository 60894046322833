import firebase from 'firebase/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import {
    getCollectionFiltered,
    getDocument,
    saveDocument,
    updateDocument,
    deleteDocument
} from './abm'

const collection = 'posts'

export default class Post {
    constructor() {
        this.id = ''
        this.locationName = ''
        this.date = new Date()
        this.img = ''
        this.description = ''
    }
}


export const getPostsRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy('date', 'desc')
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })


export const getPostsFiltered = (field = null, value = null, startAt = null, limit = 0, orderBy = null, getSize = () => { }) =>
    getCollectionFiltered({ collection, field, value, startAt, limit, orderBy, getSize })

export const getPosts = () => getCollectionFiltered({ collection, orderBy: 'date', orderDirection: 'desc' })

export const getPostData = id => getDocument(id, collection)

export const getAllPosts = () => getCollectionFiltered({ collection, orderBy: 'date', orderDirection: 'desc' })

export const savePost = data => saveDocument(refactor(data), collection)

export const updatePost = data => updateDocument(refactor(data), collection)

export const deletePost = id => deleteDocument(id, collection)

const refactor = obj => ({
    ...obj,
    descriptionToLower: obj.description?.toLowerCase()
})
