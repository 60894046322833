import React, { useEffect, useState } from 'react'
import PostForm from '../components/blog/postForm'
import { savePost, updatePost } from '../firebase/obj/post'
import { uploadImagesAsString } from '../firebase/obj/storage'
import { useNavigate } from "@reach/router"

export const PostContainer = ({ isAdmin, openForm, setOpenForm }) => {
    const navigate = useNavigate()

    const handleSetOpenForm = state => {
        setOpenForm(state)
        !state && navigate('/home', {
            state: {
                refresh: true,
            }
        })
    }

    const handleSavePost = (post, updateProgress, setError) => {
        const sources = [...post.images]
        post.images = []
        //console.log('handleSavePost ==>', post)
        savePost(post).then(({ id }) => {

            const setUrls = urls => {
                if (urls.length === 0) {
                    setError('No se obtuvieron url de imagenes guardadas')
                    return console.log('No se obtuvieron url de imagenes guardadas')
                }
                //urls.map(url => console.log('url', url))
                post.id = id
                post.images = [...urls]
                updatePost(post)
                updateProgress(0)
                handleSetOpenForm(false)
            }

            uploadImagesAsString({ postId: id, sources, setUrls, updateProgress, setError })
        })
    }

    return (
        <PostForm
            isAdmin={isAdmin}
            openForm={openForm}
            setOpenForm={handleSetOpenForm}
            savePost={handleSavePost}
        />
    )
}

export default PostContainer