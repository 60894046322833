import React, { useState, useEffect } from 'react'
import { getCoverImages } from '../firebase/obj/storage'
import Carousel from '../components/carousel'

export const CoverContainer = () => {
    const [coverImages, setCoverImages] = useState([])

    const loadImages = () => {
        console.log('Cargo imagenes')
        getCoverImages()
            .then(images => setCoverImages(images))
            .catch(setCoverImages([]))
    }

    useEffect(() => loadImages(), [])

    return (
        <Carousel items={coverImages} />
    )
}

export default CoverContainer